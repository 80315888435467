import { Fragment, useEffect, useRef, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Link, useLocation } from 'react-router-dom'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useSelector, useDispatch } from 'react-redux'
import { changeDarkMode } from '../../../reducers/dark'
import './Nav.css'
const scrolled = 'top-0 w-[100%]'
const noScroll = 'top-[16px] mx-[16px] w-[calc(100%-32px)]'
const Nav = ({ tabs }) => {
  const dispatch = useDispatch()
  const { isDarkMode } = useSelector((state: any) => state.dark)
  const [dark, setDark] = useState(isDarkMode)
  const buttonRef = useRef() as any
  const [isScrolled, setIsScrolled] = useState(false)

  const location = useLocation().pathname.substring(1)

  const changeBackground = () => {
    if (window.scrollY >= 82) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
    return () => window.removeEventListener('scroll', changeBackground)
  })

  return (
    <header className={`nav-header fixed z-[60] duration-500 ${isScrolled ? scrolled : noScroll}`}>
      <Popover
        className={`relative z-40 ${isDarkMode ? "bg-[#1c1c1c] border-[#1c1c1c]" : "bg-white  border-white"} duration-500 ${isScrolled ? ' border-b shadow' : isDarkMode ? 'rounded-[10px] border border-[#1c1c1c]' : 'rounded-[10px] border border-white'
          }`}
      >
        <div className="mx-auto h-[80px] max-w-[1888px] px-4 sm:px-6">
          <div className="flex h-[80px] items-center justify-between md:space-x-10 xl:justify-start">
            <div className="flex h-[80px] items-center justify-start lg:w-0 lg:flex-1">
              <Link to="/" className="flex h-[80px] items-center">
                {isDarkMode ?
                  <img className="h-[60px]  nav-header-img" src="/imgs/Logos/logowhite.png" alt="" /> :
                  <img className="h-[60px]  nav-header-img" src="/imgs/Logos/Logo1.png" alt="" />
                }
              </Link>
            </div>
            <div className="-my-2 -mr-2 xl:hidden">

              <Popover.Button className={`inline-flex items-center justify-center rounded-md ${isDarkMode ? "bg-[#111]  hover:bg-[#000]  text-[#bebebe] hover:text-white" : "bg-white  hover:bg-gray-100  text-gray-400 hover:text-gray-500"} p-2    `}>
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-9 w-9" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden  max-w-[775px]  xl:flex">


              {tabs.map((item, index) => (
                <div className='px-6' key={index}>

                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                    }}
                    key={item.name}
                    to={item.href}
                    className={`inter  pt-[31px] pb-[26px] text-base text-[14px] transition-colors hover:text-[#70c2b4] font-medium tracking-normal ${isDarkMode ? "text-white" : "text-black"}  ${(location === item.name.toLocaleLowerCase().replace(/ +/g, '') ||
                      (location === '' && item.name.toLocaleLowerCase() === 'home') ||
                      (location === 'foodsafety' && item.name === 'HSE')) &&
                      (`activeNav`)
                      }  `}
                  >
                    {item.name}
                  </Link>
                </div>
              ))}
            </Popover.Group>
            <div className=":w-0 hidden items-center justify-end xl:flex xl:flex-1">
              <Link
                to="/contactus"
                className={`inter ml-8 mr-6 inline-flex max-h-[50px] items-center justify-center whitespace-nowrap rounded-[30px] border border-transparent bg-[#70C2B4] px-6  py-2 text-[14px] text-base font-medium text-white shadow-sm `}
              >
                CONTACT
              </Link>
              <label className="relative inline-flex items-center cursor-pointer ">
                <input type="checkbox" value="" className="sr-only peer" checked={dark} onChange={(s) => { setDark(s.target.checked); dispatch(changeDarkMode(s.target.checked)) }} />
                <div className="w-[50px] h-[30px] darkmoood bg-gray-200 peer-focus:outline-none   rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-[#70C2B4] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-[#70C2B4] after:border-gray-300 after:border after:rounded-full after:h-[26px] after:w-[26px] after:transition-all  peer-checked:bg-black"></div>
              </label>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="fixed w-full h-full inset-x-0 top-0 origin-top-right transform  transition xl:hidden"
          >
            <div className={`nav-main-container divide-y-2 divide-gray-50 rounded-lg ${isDarkMode ? "bg-[#1c1c1c]" : "bg-white"}  shadow-lg ring-1 ring-black ring-opacity-5 h-full`}>
              <div className="px-5 pt-5 pb-6 nav-Container">
                <div className={`flex items-center justify-between nav-img-container ${isDarkMode ? "border-[#333]" : "border-[#ddd]"} border-b md:border-r md:border-b-0`}>
                  <div className='img-container'>
                    {isDarkMode ?
                      <img className="h-[60px] nav-img" src="/imgs/Logos/logowhite.png" alt="Your Company" /> :
                      <img className="h-[60px] nav-img" src="/imgs/Logos/Logo1.png" alt="Your Company" />
                    }
                  </div>
                  <div className="-mr-2 close-container">
                    <Popover.Button
                      ref={buttonRef}
                      className={`inline-flex items-center justify-center rounded-md ${isDarkMode ? "bg-[#111]  hover:bg-[#000]  text-[#bebebe] hover:text-white  focus:border-[#000] focus:ring-1 focus:ring-[#111]" : "bg-white  text-gray-400 hover:bg-gray-100 hover:text-gray-500  focus:border-white focus:ring-1 focus:ring-white"}  p-2 focus:outline-none focus:border-white focus:ring-1 focus:ring-white`}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6 buttons-container">
                  <nav className="grid gap-y-8">
                    {tabs.map((item) => (
                      <Link
                        key={item.name}
                        onClick={() => { buttonRef.current?.click(); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}
                        to={item.href}
                        className={`-m-3 flex items-center rounded-md p-3  `}
                      >
                        <item.icon
                          className="h-6 w-6 flex-shrink-0 text-[#70C2B4]"
                          aria-hidden="true"
                        />
                        <span className={`inter ml-3 text-base font-medium ${isDarkMode ? "text-white " : "text-gray-900"}  hover:text-[#70c2b4] ${(location === item.name.toLocaleLowerCase().replace(/ +/g, '') ||
                          (location === '' && item.name.toLocaleLowerCase() === 'home') ||
                          (location === 'foodsafety' && item.name === 'HSE')) &&
                          (`text-[#70c2b4]`)
                          }`}>
                          {item.name}
                        </span>
                      </Link>
                    ))}

                    <div>
                      <Link
                        to="/contactus"
                        onClick={() => { buttonRef.current?.click(); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}
                        className="mb-8 inter flex w-full items-center justify-center rounded-md border border-transparent bg-[#70C2B4] px-6 py-2 text-base font-medium text-white shadow-sm hover:bg-[#70C2B4]"
                      >
                        CONTACT
                      </Link>
                      <label className="relative inline-flex items-center cursor-pointer ">
                        <input type="checkbox" value="" className="sr-only peer" checked={dark} onChange={(s) => { setDark(s.target.checked); dispatch(changeDarkMode(s.target.checked)) }} />
                        <div className="w-[50px] h-[30px] darkmoood bg-gray-200 peer-focus:outline-none   rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-[#70C2B4] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-[#70C2B4] after:border-gray-300 after:border after:rounded-full after:h-[26px] after:w-[26px] after:transition-all  peer-checked:bg-black"></div>
                      </label>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  )
}

export default Nav
