import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import './GetInTouch.css'
import axios from 'axios'
import catchError from '../../utils/catchError'
import isEmail from 'validator/lib/isEmail'
import { useSelector } from 'react-redux'

const GetInTouch = ({ data }) => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  const [info, setInfo] = useState({
    name: '',
    mailFrom: '',
    mobile: '',
    subject: '',
    body: '',
    MailTo: ''
  })
  const mobileRegExp = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/g;
  const [errorMsg, setErrorMsg] = useState(["Please Enter a valid "])
  const [serverRes, setServerRes] = useState<null | string>(null)
  const [formLoading, setFormLoading] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const { name, mailFrom, mobile, subject, body } = info

  useEffect(() => {
    const isInfo = Object.values(info).every((item) => Boolean(item))
    isInfo ? setSubmitDisabled(false) : setSubmitDisabled(true)
    if (errorMsg.length < 2) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [info, errorMsg])

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setInfo((prev) => ({ ...prev, [name]: value }))
    if (name === "name") {
      if (value.length < 3) {
        if (!errorMsg.join().includes("name")) {
          setErrorMsg((prev) => { return [...prev, "name"] })
        }
      } else {
        setErrorMsg((prev) => { return prev.filter(x => x !== "name") })
      }

    }
    if (name === "mailFrom") {
      if (!isEmail(value)) {
        if (!errorMsg.join().includes("email")) {
          setErrorMsg((prev) => { return [...prev, "email"] })
        }
      } else {
        setErrorMsg((prev) => { return prev.filter(x => x !== "email") })
      }
    }
    if (name === "mobile") {

      if (!value.match(mobileRegExp)) {
        if (!errorMsg.join().includes("mobile number")) {
          setErrorMsg((prev) => { return [...prev, "mobile number"] })
        }
      } else {
        setErrorMsg((prev) => { return prev.filter(x => x !== "mobile number") })
      }
    }
    if (name === "subject") {
      if (value.length < 3) {
        if (!errorMsg.join().includes("subject")) {
          setErrorMsg((prev) => { return [...prev, "subject"] })
        }
      } else {
        setErrorMsg((prev) => { return prev.filter(x => x !== "subject") })
      }
    }

    if (name === "body") {

      if (value.length < 20) {
        if (!errorMsg.join().includes("message")) {
          setErrorMsg((prev) => { return [...prev, "message"] })
        }
      } else {
        setErrorMsg((prev) => { return prev.filter(x => x !== "message") })
      }
    }
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setFormLoading(true)

    try {
      const res = await axios.get(
        `https://api.gemini.media/auth?Valtoken=test`
      );
      const send = await axios.post(data.contactAPI, info, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${res.data.data}`,
        }
      })
      if(send.status===200){
        setServerRes("Send Successfully!")
      }
      setInfo({
        name: '',
        mailFrom: '',
        mobile: '',
        subject: '',
        body: '',
        MailTo: ''
      })
    } catch (error: any) {
      setServerRes(error.message)
      setFormLoading(false)
    }
    setFormLoading(false)
  }
  // const handleCloseError = (e: any) => {
  //   e.preventDefault()
  //   setErrorMsg(null)
  // }
  return (

    <section id="getintouch" className={`${isDarkMode ? "bg-[#1c1c1c]" : "bg-white"} flex flex-col`}>
      <div className='mainContainer'>
        <div className="getintouchContainer">
          <div className="left-getintouch">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2, delay: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="head"
            >
              <div className="line"></div>
              <div className="inter text-[14px] font-semibold text-[#70C2B4] ">{data.headText}</div>
            </motion.div>
            <motion.h2
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className={`left-title playfair text-[58px] font-black ${isDarkMode ? "text-white" : "text-black"}`}
            >
              {data.title}
            </motion.h2>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2, delay: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="getintouchInfo"
            >
              <div>
                <div className="Info-Head">
                  <img src="/imgs/Contact/Location@2x.webp" alt="address" />
                  <p className={`inter text-[17px] font-bold ${isDarkMode ? "text-white" : "text-[#0C0C0C]"}`}>ADDRESS</p>
                </div>
                <div className="Info-body">
                  <p className={`${isDarkMode ? "text-[#bebebe]" : "text-[#6e6e6e]"}`}>{data.address}</p>
                </div>
              </div>
              <div>
                <div className="Info-Head">
                  <img src="/imgs/Contact/Location-1@2x.webp" alt="phone" />
                  <p className={`inter text-[17px] font-bold ${isDarkMode ? "text-white" : "text-[#0C0C0C]"}`}>PHONE</p>
                </div>
                <div className="Info-body">
                  <p className={`${isDarkMode ? "text-[#bebebe]" : "text-[#6e6e6e]"}`}>
                    <span className={`font-medium ${isDarkMode ? "text-white" : "text-black"}`}>Tel:</span> {data.phone.tel}
                  </p>
                  <p className={`${isDarkMode ? "text-[#bebebe]" : "text-[#6e6e6e]"}`}>
                    <span className={`font-medium ${isDarkMode ? "text-white" : "text-black"}`}>Fax:</span> {data.phone.fax}
                  </p>
                </div>
              </div>
              <div>
                <div className="Info-Head">
                  <img src="/imgs/Contact/Location-2@2x.webp" alt="email" />
                  <p className={`inter text-[17px] font-bold ${isDarkMode ? "text-white" : "text-[#0C0C0C]"}`}>EMAIL</p>
                </div>
                <div className="Info-body">
                  <p className={`${isDarkMode ? "text-[#bebebe]" : "text-[#6e6e6e]"}`}>{data.email}</p>
                </div>
              </div>
              <div>
                <div className="Info-Head">
                  <img src="/imgs/shared/share.png" alt="follow us" />
                  <p className={`inter text-[17px] font-bold ${isDarkMode ? "text-white" : "text-[#0C0C0C]"}`}>FOLLOW US</p>
                </div>
                <div className="ml-[34px] flex gap-[10px]">
                  <a className="socialLink" href={data.socialLinks.facebook} target='_blank'
                    rel='noreferrer'>
                    <img src="/imgs/shared/facebook@2x.webp" alt="facebook" />
                  </a>
                  <a className="socialLink" href={data.socialLinks.linkedin} target='_blank'
                    rel='noreferrer'>
                    <img src="/imgs/shared/linkedin-3@2x.webp" alt="linkedin" />
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
          {formLoading ? (
            <div className="fixed top-0 left-0 z-50 block h-full w-full bg-white opacity-75">
              <span className="relative right-10 top-60 my-0 mx-auto block h-0 w-0 text-blue-500 opacity-75">
                <svg xmlns="http://www.w3.org/2000/svg">
                  <circle
                    cx="50"
                    cy="50"
                    fill="none"
                    stroke="#1d3f72"
                    strokeWidth="10"
                    r="35"
                    strokeDasharray="164.93361431346415 56.97787143782138"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      repeatCount="indefinite"
                      dur="1s"
                      values="0 50 50;360 50 50"
                      keyTimes="0;1"
                    />
                  </circle>
                </svg>
              </span>
            </div>
          ) : (
            null
          )}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.75, delay: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className={`sendus-form ${isDarkMode ? "bg-[#111]" : "bg-[#ffffff]"} z-[2]`}
          >

            <motion.h3
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className={`playfair text-[24px] font-bold ${isDarkMode ? "text-white" : "text-black "}`}
            >
              Send Us A Message
            </motion.h3>
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className={`inter mt-[30px] mb-[40px] text-[17px] font-normal ${isDarkMode ? "text-[#bebebe]" : "text-[#6E6E6E]"}`}
            >
              We are here to answer any questions you may have. Do not hesitate to contact us in case
              of any questions or inquiries.
            </motion.p>
            <div className="flex flex-col form-container ">
              <motion.input
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.2 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 }
                }}
                value={name}
                onChange={handleChange}
                className={` ${errorMsg.includes("name")?" border-red-500":isDarkMode?"border-[#111]":"border-[#f3f3f3]"} border-[1px]   my-[9px] form-input ${isDarkMode ? "bg-[#1c1c1c] text-[#bebebe]" : "bg-[#f3f3f3] text-[#6e6e6e]"}`}
                type="text"
                name="name"
                required
                placeholder="Name*"
              />
              <motion.input
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.2 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 }
                }}
                value={mailFrom}
                onChange={handleChange}
                className={` ${errorMsg.includes("email")?" border-red-500":isDarkMode?"border-[#111]":"border-[#f3f3f3]"} border-[1px]   my-[9px] form-input ${isDarkMode ? "bg-[#1c1c1c] text-[#bebebe]" : "bg-[#f3f3f3] text-[#6e6e6e]"}`}
                type="email"
                name="mailFrom"
                required
                placeholder="Email*"
              />
              <motion.input
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.2 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 }
                }}
                value={mobile}
                onChange={handleChange}
                className={` ${errorMsg.includes("mobile number")?" border-red-500":isDarkMode?"border-[#111]":"border-[#f3f3f3]"} border-[1px]   my-[9px] form-input ${isDarkMode ? "bg-[#1c1c1c] text-[#bebebe]" : "bg-[#f3f3f3] text-[#6e6e6e]"}`}
                type="tel"
                name="mobile"
                required
                placeholder="Mobile*"
              />
              <motion.input
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.2 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 }
                }}
                value={subject}
                onChange={handleChange}
                className={` ${errorMsg.includes("subject")?" border-red-500":isDarkMode?"border-[#111]":"border-[#f3f3f3]"} border-[1px]   my-[9px] form-input ${isDarkMode ? "bg-[#1c1c1c] text-[#bebebe]" : "bg-[#f3f3f3] text-[#6e6e6e]"}`}
                type="text"
                name="subject"
                required
                placeholder="Subject*"
              />
              <motion.textarea
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.2 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 }
                }}
                value={body}
                onChange={handleChange}
                className={`relative ${errorMsg.includes("message")?"  border-red-500":isDarkMode?"border-[#111]":"border-[#f3f3f3]"} my-[9px] border-[1px]  form-textarea ${isDarkMode ? "bg-[#1c1c1c] text-[#bebebe]" : "bg-[#f3f3f3] text-[#6e6e6e]"}`}
                rows={6}
                cols={50}
                name="body"
                required
                placeholder="Message*"
              ></motion.textarea>
              {serverRes !== null ?
                <p className={`inter absolute form-state lg:text-[14px]   w-fit  ${serverRes==="Send Successfully!"?"text-green-500 ":" text-red-500"}`}>{serverRes}</p> : null}
              {errorMsg.length > 1 ?
                <p className='inter form-state text-red-500 lg:text-[14px]   '>
                  {errorMsg.map((item, i) => (
                    i < 2 ? <span>{item}</span> : <span> and {item}</span>
                  ))}
                .</p> : null}
            </div>
            <motion.button
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2, delay: 0.2 }}
              disabled={submitDisabled}
              onClick={handleSubmit}
              className={`inter h-[50px] mt-[50px] w-[150px] rounded-[30px] bg-[#70C2B4] text-[13px] font-semibold text-white ${submitDisabled ? "opacity-75 cursor-not-allowed" : ""} `}
            >
              SEND
            </motion.button>
          </motion.div>
        </div>
      </div>
      <motion.iframe
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.2, delay: 0.2 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 }
        }}
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.155488825028!2d31.208374976147933!3d30.06107721778148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14584118acf66e1d%3A0x2774c50b03a70854!2s160%2026th%20of%20July%20Corridor%2C%20Al%20Huwaiteyah%2C%20Agouza%2C%20Giza%20Governorate%203753612!5e0!3m2!1sen!2seg!4v1671972997100!5m2!1sen!2seg"
        width="100%"
        height="500"
        className="map-iframe z-[1]"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></motion.iframe>
    </section>
  )
}

export default GetInTouch
