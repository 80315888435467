import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import Slider from 'infinite-react-carousel'
import './GallerySlider.css'
import VideoModel from '../shared/VideoModel'

const GallerySlider = ({ data, isDarkMode }) => {
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState('')
  const sliderRef = useRef<any>()
  const [showCount, setShowCount] = useState(0)

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSliderAnimating, setIsSliderAnimating] = useState(false);
  const handleVideo = (video) => {
    setUrl(video)
    setOpen(true)
  }
  const changeBackground = () => {
    if (window.innerWidth >= 1024) {
      setShowCount(3)
    } else if (window.innerWidth >= 768) {
      setShowCount(2)
    } else {
      setShowCount(1)
    }
  }


  useEffect(() => {
    changeBackground()
    window.addEventListener('resize', changeBackground)
    return () => {
      window.removeEventListener('resize', changeBackground)
    }
  }, [])
  return (
    <section className={`${isDarkMode?"bg-[#1c1c1c]":"bg-white"} pb-20`}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 }
        }}
        className="w-full pt-[50px]  md:pt-[70px] "
      >
        <p className={`inter main-title-back h-fit text-center  text-[60px] font-[900] ${isDarkMode?"text-[#252525]":"text-[#f3f3f3]"}  lg:text-[120px]`}>
          {data.title}
        </p>
        <p className={`playfair main-title-front mt-[-60px] text-center text-[50px] font-[900] ${isDarkMode?"text-white":"text-[#121212]"}  lg:mt-[-140px] lg:text-[60px]`}>
          {data.feature}
        </p>
        {/* <p className={`inter mx-auto mt-8 mb-10 text-center text-[15px] md:text-[16px] lg:text-[18px] ${isDarkMode?"text-[#bebebe]":"text-[#6e6e6e]"}  lg:mt-8 lg:mb-0 md:px-[20%]`}>
          {data.description}
        </p> */}
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 }
        }}
        className={`gallerySlider-swiper`}
      >
        <button
          type="button"
          className={`arrowButton gallerySlider-prev ${currentSlide === 0 ? isDarkMode?"disabledArrowDark":"disabledArrow" : ""}`}
          onClick={() => {
            if (!isSliderAnimating && currentSlide > 0) {
              sliderRef?.current?.slickPrev();
            }
          }}
          disabled={currentSlide === 0 || isSliderAnimating}
        >
          <img src="/imgs/shared/prevWhite.webp" className="prevArrow" alt="" />
        </button>
        <Slider
          slidesToShow={showCount}
          arrows={false}
          ref={sliderRef}
          centerPadding={50}
          className="slider-carousel"
          beforeChange={() => setIsSliderAnimating(true)}
          afterChange={(index) => {
            setCurrentSlide(index);
            setIsSliderAnimating(false);
          }}
        >
          {data.videos.map((item, index) => (
            <div>
              <div className="gallerySlider-card" key={index} >
                <img src={`/imgs/Gallery/${item.img}`} alt={item.img} className='border rounded-md'/>
                <button className="video-play-btn" onClick={() => handleVideo(item.video)}>
                  <div className="gallery-btn-box">
                    <img src="/imgs/shared/play-button-arrowhead.webp" alt="" />
                  </div>
                </button>
              </div>
            </div>
          ))}
        </Slider>
        <button
          type="button"
          className={`arrowButton gallerySlider-next ${currentSlide === data.videos.length - showCount ? isDarkMode?"disabledArrowDark":"disabledArrow" : ""}`}
          onClick={() => {
            if (!isSliderAnimating && currentSlide < data.videos.length - showCount) {
              sliderRef?.current?.slickNext();
            }
          }}
          disabled={currentSlide === data.videos.length - showCount || isSliderAnimating}

        >
          <img src="/imgs/shared/nextWhite.webp" className="nextArrow" alt="" />
        </button>
      </motion.div>
      <VideoModel open={open} setOpen={setOpen} url={url} />
    </section>
  )
}

export default GallerySlider
