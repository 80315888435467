import React from 'react'
import { Helmet } from 'react-helmet'
import { Testimotial } from '../components/about/Testimotial'
import { Timeline } from '../components/about/Timeline'
import Homeslider from '../components/home/Homeslider'
import { Whoweare } from '../components/shared/whoarewe/Whoweare'

const About = () => {
  const whoweare = {
    headText: 'WHO WE ARE',
    title: 'Abela Egypt',
    description: `Welcome to Abela catering, where we have been providing comprehensive life support plans since our establishment on April 12, 1986. Our company is dedicated to delivering top-notch catering, camp construction, and management services to clients across various industries.
    With a strong focus on flexibility, we pride ourselves on tailoring our services to meet the unique requirements and conditions of each individual project.
    `,
    img: '/imgs/Home/WhoWeAre1.jpg',
    id: 'Whoweare'
  }

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABELA | ABOUT</title>
        
        <link rel="icon" type="image/webp" href="/imgs/Logos/Icon.png" sizes="16x16" />
      </Helmet>
      <Homeslider />
      <Whoweare data={whoweare} />
      <Timeline />
      <Testimotial />
    </div>
  )
}

export default About
