import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ReactPlayer from 'react-player'

export default function VideoModel({ open, setOpen, url }) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[70] "
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-[70]  bg-black bg-opacity-[0.8] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-[70] overflow-y-auto">
          <button
            className="close-model absolute top-5 right-5 w-[50px]"
            onClick={() => setOpen(false)}
          >
            <img src="/imgs/shared/close-model.webp" alt="close-model-btn" />
          </button>
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[100%] w-auto transform overflow-hidden rounded-lg bg-white text-left shadow-xl  transition-all sm:my-8 sm:h-[600px]  lg:h-[700px] ">
                <video controls autoPlay loop style={{height:"100%"}}>
                  <source src={url} type="video/mp4"/> 
                </video>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
