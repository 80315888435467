import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import './Footer.css'

const Footer = ({ tabs }) => {
  const location = useLocation().pathname.substring(1)

  const [isScrolled, setIsScrolled] = useState(false)
  const changeBackground = () => {
    if (document.body.offsetHeight - window.pageYOffset >= 1230) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
    return () => window.removeEventListener('scroll', changeBackground)
  })

  return (
    <section className='relative  flex flex-col justify-center items-center bg-[#0C0C0C] bg-[url("/public/imgs/shared/rs_w_1920_m.webp")] bg-cover  bg-no-repeat	pt-[50px]  md:bg-[url("/public/imgs/shared/rs_w_1920_m@2x.webp")]  '>
      <div className='mainContainer'>
        <div className=''>


          <div className="flex footer-container  lg:items-start  flex-wrap justify-center items-center gap-8 md:gap-0 md:justify-between  md:flex-row  flex-col">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="footer-img-container lg:m-auto w-auto h-[120px] md:h-[160px] lg:max-h-[200px] xl:h-[200px] lg:mx-0 "
            >
              <Link to="/" onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
              }}>
                <img src="/imgs/shared/meiLogo@2x2.webp" alt="" className='w-auto h-full' />
              </Link>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="footer-site-map   lg:mx-0 lg:mt-0 lg:mb-0 "
            >
              <p className="playfair mb-6 text-center font-[900] text-white lg:text-start lg:text-[24px]">
                Site Map
              </p>
              <div className="flex flex-col  lg:items-start gap-[6px]">
                {tabs.map((item) => (
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                    }}
                    key={item.name}
                    to={item.href}
                    className={`inter  text-[13px] opacity-[0.7] lg:text-[15px] ${location === item.name.toLocaleLowerCase().replace(/ +/g, '') ||
                      (location === '' && item.name.toLocaleLowerCase() === 'home') ||
                      (location === 'foodsafety' && item.name === 'HSE')
                      ? 'text-[#70C2B4]'
                      : 'text-[#F2F6F7]'
                      }`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="footer-contact-us  lg:mx-0  md:w-[45%] lg:w-[33%] flex flex-col  items-center md:items-start"
            >
              <p className="playfair mb-6  font-[900] text-white text-start lg:text-[24px]">
                Contact Us
              </p>
              <div className=" flex flex-col gap-2 lg:mx-0 ">
                <div className="flex space-x-[31px] lg:space-x-[33px]">
                  <p className="inter text-[13px]  text-white lg:text-[15px]">ADDRESS:</p>
                  <p className="inter ml-4 text-[12px] text-[#F2F6F7] opacity-[0.7] lg:text-[15px]">
                    160, 26th of July Corridor, Al Agouzah, Cairo, Egypt
                  </p>
                </div>
                <div className="flex items-center space-x-[53px] lg:space-x-[58px]">
                  <p className="inter text-[13px]  text-white lg:text-[15px]">EMAIL:</p>
                  <p className=" inter text-[12px] text-[#F2F6F7] opacity-[0.7] lg:text-[15px]">
                    info@abelaegypt.com
                  </p>
                </div>
                <div className="flex items-center space-x-[69px] lg:space-x-[75px]">
                  <p className="inter text-[13px]  text-white lg:text-[15px]">TEL:</p>
                  <p className=" inter text-[12px] text-[#F2F6F7] opacity-[0.7] lg:text-[15px]">
                    (+2) 02 330 50 040
                  </p>
                </div>
                <div className="flex items-center space-x-[69px] lg:space-x-[75px]">
                  <p className="inter text-[13px]  text-white lg:text-[15px]">FAX:</p>
                  <p className=" inter text-[12px] text-[#F2F6F7] opacity-[0.7] lg:text-[15px]">
                    (+2) 02 330 50 020
                  </p>
                </div>
                <div className="flex items-center space-x-[16px] lg:space-x-[15px]">
                  <p className="inter text-[13px]  text-white lg:text-[15px]">FOLLOW US:</p>
                  <div className="ml-[34px] flex gap-[10px]">
                    <a
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-[50%] bg-[#70c2b4]"
                      href="https://www.facebook.com/AbelaEgypt"
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        className="h-[12px] w-[12px]"
                        src="/imgs/shared/facebook@2x.webp"
                        alt="facebook"
                      />
                    </a>

                    <a
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-[50%] bg-[#70c2b4]"
                      href="https://www.linkedin.com/company/abela-egypt/"
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        className="h-[12px] w-[12px]"
                        src="/imgs/shared/linkedin-3@2x.webp"
                        alt="linkedin"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
          <hr
            style={{
              borderTop: '2px dotted #707070',
              width: '100%',
              margin: '20px auto',
              opacity: '1'
            }}
          />
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="flex  items-center justify-between pb-5 flex-col gap-5 md:gap-0 md:flex-row  "
          >
            <p className="inter text-[12px] text-[#f2f6f7] opacity-[0.7]  lg:text-[14px]">
              Copyright © 2023 <a href='https://gemini.media/' target='_blank' rel="noreferrer" style={{ textDecoration: "underline" }}>Gemini Media</a>, All Rights Reserved
            </p>
            <div className='flex flex-row items-center justify-center max-h-[50px] gap-8'>
              <img src='/imgs/shared/year(2).png' alt='' className='w-full h-[50px]' />
              <img src='/imgs/shared/year(1).png' alt='' className='w-full h-[50px]' />
            </div>
          </motion.div>
          <button
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }}
            className={`${isScrolled ? 'arrow-to-top-fixed' : 'arrow-to-top'
              } arrow-top bg-[#70C2B4] text-white`}
          >
            <img src="/imgs/shared/arrow-to-top.webp" alt="arrow-to-top" />
          </button>
        </div>
      </div>
    </section>
  )
}

export default Footer
