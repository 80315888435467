import React from 'react'
import { Helmet } from 'react-helmet'
import { Catering } from '../components/services/Catering'
import { Facility } from '../components/services/Facility'
import { Hero } from '../components/shared/hero/Hero'

const Services = () => {
  const content = {
    head: 'WELCOME TO ABELA',
    title: 'Services',
    shortTitle: 'Services',
    slogan: `We serve quality meals at affordable prices to our customers. Our Chef sits together with our clients representatives to get their ideas and suggestions to what type of menus they prefer`,
    href: '/services',
    linkTo: '/services#catering',
    backGround: '/imgs/Services/HeroImage.webp',
    mobBackGround: '/imgs/Services/HeroImage-Mobile.webp'
  }
  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABELA | SERVICES</title>
        
        <link rel="icon" type="image/webp" href="/imgs/Logos/Icon.png" sizes="16x16" />
      </Helmet>
      <Hero data={content} />
      <Catering />
      <Facility />
    </div>
  )
}

export default Services
