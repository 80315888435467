import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import Slider from 'infinite-react-carousel'
import './Catering.css'
import MenuModel from './MenuModel'
import CateringCard from './CateringCard'
import { useSelector } from 'react-redux'

const content = [
  {
    title: 'Coffee Break',
    description:
      'We offer according to your needs and desire a wide range of coffee, tea, juices, sweet and savory snacks, fresh fruit and vegetables.',
    img: '/imgs/Services/CoffeeBreak.jpg',
    menu: {
      name: 'Sample Coffee Break Menu',
      items: [
        {
          img: '01.CoffeeBreak-01.png',
          subTitle: 'Sample One',
          array: [
            {
              icon: 'croisant 2.webp',
              itemName: 'Mini Croissant',
              descripetion: ''
            },
            {
              icon: 'Mini-Danish 2.webp',
              itemName: 'Mini Danish',
              descripetion: ''
            },
            {
              icon: 'mini-pizza 1.webp',
              itemName: 'Mini Pizza',
              descripetion: '(Tuna- mushroom)'
            },
            {
              icon: 'tea.webp',
              itemName: 'Tea',
              descripetion: ''
            },
            {
              icon: 'e 1.webp',
              itemName: 'English Cake',
              descripetion: ''
            },
            {
              icon: 'coffee.webp',
              itemName: 'Coffee',
              descripetion: ''
            },
            {
              icon: 'mini-sandwich 2.webp',
              itemName: 'Mini Sandwich',
              descripetion: '(Cheese tomamto - Tuna mousse - Smooked beef)'
            },
            {
              icon: 'water.webp',
              itemName: 'Water',
              descripetion: ''
            },
          ]
        },
        {
          img: '01.CoffeeBreak-02.png',
          subTitle: 'Sample Two',
          array: [
            {
              icon: 'cheese 2.webp',
              itemName: 'Cheese pate',
              descripetion: ''
            },
            {
              icon: 'Assorted-mini-sandwich 1.webp',
              itemName: 'Mini Burger Sandwich',
              descripetion: ''
            },
            {
              icon: 'mini-pizza 1.webp',
              itemName: 'Mini Pizza',
              descripetion: '(Veggies - salami)'
            },
            {
              icon: 'mini-sandwich 2.webp',
              itemName: 'Mini Sandwich',
              descripetion: '(Roast Beef - Smooked Turkey - Roasted Vegetables)'
            },
            {
              icon: 'Cup-cake 1.webp',
              itemName: 'Cup Cake',
              descripetion: ''
            },
            {
              icon: 'coffee.webp',
              itemName: 'Coffee',
              descripetion: ''
            },
            {
              icon: 'tea.webp',
              itemName: 'Tea',
              descripetion: ''
            },
            {
              icon: 'water.webp',
              itemName: 'Water',
              descripetion: ''
            },
            {
              icon: 'Mini-fruit-tartlet 1.webp',
              itemName: 'Mini Fruit Tartlet',
              descripetion: ''
            },
          ]
        },
        {
          img: '01.CoffeeBreak-03.png',
          subTitle: 'Sample Three',
          array: [
            {
              icon: 'Assorted-Cold-cuts 1.webp',
              itemName: 'Assorted Cold cuts',
              descripetion: ''
            },
            {
              icon: 'Muffins 1.webp',
              itemName: 'Muffins',
              descripetion: ''
            },
            {
              icon: 'Cheese-balls 1.webp',
              itemName: 'Cheese balls',
              descripetion: '(Veggies - salami)'
            },
            {
              icon: 'Open-face-sandwich 1.webp',
              itemName: 'Open Face Sandwich',
              descripetion: '(Smooked Turkey - Smoked Salmon - Blue Cheese)'
            },
            {
              icon: 'Chicken-satay 1.webp',
              itemName: 'Chicken Satay',
              descripetion: ''
            },
            {
              icon: 'coffee.webp',
              itemName: 'Coffee',
              descripetion: ''
            },
            {
              icon: 'tea.webp',
              itemName: 'Tea',
              descripetion: ''
            },
            {
              icon: 'water.webp',
              itemName: 'Water',
              descripetion: ''
            },
            {
              icon: 'Fresh-fruit-skewers 1.webp',
              itemName: 'Fresh Fruit Skewers',
              descripetion: ''
            },
          ]
        },
      ]
    }
  },
  {
    title: 'Events Catering',
    description:
      'We specialize in providing staff lunches from individual bagged lunches to full service hot & cold buffet catering.',
    img: '/imgs/Services/eventCatering.webp',
    menu: {
      name: 'Sample Catering Open Buffet',
      items: [
        {
          img: '02.CateringOpenBuffet.png',
          subTitle: 'Sample Menu',
          array: [
            {
              icon: 'main Course.webp',
              itemName: 'Main Course',
              descripetion: '(Grilled fish with Mounier sauce - Grilled beef fillet with pepper sauce - Chicken sweet and sour-shredded beef with vegetables)'
            },
            {
              icon: 'dessert.webp',
              itemName: 'Dessert',
              descripetion: '(Chesse cake - carrot cake - chocolate eclair - Assorted gateaux soiree)'
            },
            {
              icon: 'Penne 1.webp',
              itemName: 'Served with',
              descripetion: '(Pomme gratin - White rice with herbs - penne arabiata)'
            },
            {
              icon: 'Salad 1.webp',
              itemName: 'Salad',
              descripetion: '(Chef salad - Beetroot salad - spice potato salad - rucola salad)'
            },


            {
              icon: 'soup.webp',
              itemName: 'Soup',
              descripetion: '(Chicken cream soup)'
            },
          ]
        }
      ]
    }
  },
  {
    title: 'Cocktail Reception',
    description:
      'Cocktail parties are the perfect kind of setting that allows your guests to socialize and let conversations between people flow.',
    img: '/imgs/Services/coct.webp',
    menu: {
      name: 'Sample Cocktail Menu',
      items: [
        {
          img: '03.CocktailReception-01.png',
          subTitle: 'Sample One',
          array: [
            {
              icon: 'Vegetables-spring-rolls 1.webp',
              itemName: 'Vegetables spring rolls',
              descripetion: ''
            },
            {
              icon: 'Fried-fish 1.webp',
              itemName: 'Fried fish with rosemary sauce',
              descripetion: ''
            },
            {
              icon: 'Chicken-with-avocado 1.webp',
              itemName: 'Chicken with avocado',
              descripetion: ''
            },
            {
              icon: 'Parmesan-quiche 1.webp',
              itemName: 'Parmesan quiche',
              descripetion: ''
            },
            {
              icon: 'Chicken-satay 1.webp',
              itemName: 'Chicken satay',
              descripetion: ''
            },
            {
              icon: 'Mini-fruit-tartlet 1.webp',
              itemName: 'Mini fruit tartlet',
              descripetion: ''
            },
            {
              icon: 'Assorted-mini-sandwich-2 1.webp',
              itemName: 'Assorted Mini Sandwich',
              descripetion: '(Cheddar Cheese - Smoked Turkey - Roast Beef - Grilled vegetables)'
            },
            {
              icon: 'juice 1.webp',
              itemName: 'Selection of fresh seasonal juice',
              descripetion: ''
            },
          ]
        },
        {
          img: '03.CocktailReception-02.png',
          subTitle: 'Sample Two',
          array: [
            {
              icon: 'Rap-sandwiches 1.webp',
              itemName: 'Shawerma beef and chicken Rap sandwiches',
              descripetion: ''
            },
            {
              icon: 'mini hawashi.webp',
              itemName: 'Mini hawashi',
              descripetion: ''
            },
            {
              icon: 'Shrimp-spring-rolls 1.webp',
              itemName: 'Shrimp spring rolls',
              descripetion: ''
            },
            {
              icon: 'Finger-fish 1.webp',
              itemName: 'Finger fish',
              descripetion: ''
            },
            {
              icon: 'Chicken-satay 1.webp',
              itemName: 'Chicken satay',
              descripetion: ''
            },
            {
              icon: 'juice 1.webp',
              itemName: 'Selection of fresh seasonal juice',
              descripetion: ''
            },
            {
              icon: 'Meat-Sambousek 1.webp',
              itemName: 'Meat Sambousek',
              descripetion: ''
            },
            {
              icon: 'Mini-fruit-tartlet 1.webp',
              itemName: 'Mini fruit tartlet',
              descripetion: ''
            },
            {
              icon: 'Fried-calamari 1.webp',
              itemName: 'Fried calamari',
              descripetion: ''
            },
          ]
        },
        {
          img: '03.CocktailReception-03.png',
          subTitle: 'Sample Three',
          array: [
            {
              icon: 'vege.webp',
              itemName: 'vegetables crudités with dip in salsa',
              descripetion: ''
            },
            {
              icon: 'Vegetables-spring-rolls 1.webp',
              itemName: 'Vegetables spring rolls',
              descripetion: ''
            },
            {
              icon: 'shrimps 1.webp',
              itemName: 'cocktail shrimps',
              descripetion: ''
            },
            {
              icon: 'cheese sambousek.webp',
              itemName: 'Cheese Sambousek',
              descripetion: ''
            },
            {
              icon: 'Meat-Sambousek 1.webp',
              itemName: 'Meat Sambousek',
              descripetion: ''
            },
            {
              icon: 'spanish 1.webp',
              itemName: 'Spinach Sambousek',
              descripetion: ''
            },
            {
              icon: 'mashrome 1.webp',
              itemName: 'Chicken and mushroom quiche',
              descripetion: ''
            },
            {
              icon: 'Rap-sandwiches 1.webp',
              itemName: 'Chicken rap',
              descripetion: ''
            },

            {
              icon: 'juice 1.webp',
              itemName: 'Selection of fresh seasonal juice',
              descripetion: ''
            }, {
              icon: 'Mini-sandwich-in-ciabatta-bread 1.webp',
              itemName: 'Mini Sandwich In Ciabata Bread',
              descripetion: ''
            },
            {
              icon: 'Mini-fruit-tartlet 1.webp',
              itemName: 'Mini fruit tartlet',
              descripetion: ''
            },
          ]
        },
      ]
    }
  },

  {
    title: 'Packed meals',
    description:
      'Cocktail parties are the perfect kind of setting that allows your guests to socialize and let conversations between people flow.',
    img: '/imgs/Services/PackagedMeal.webp',
    menu: {
      name: 'Sample Packed Meals',
      items: [
        {
          img: '04.PackedMeals.png',
          subTitle: 'Sample Menu',
          array: [
            {
              icon: 'rice 1.webp',
              itemName: 'Khlata rice',
              descripetion: ''
            },
            {
              icon: 'grilled-vegetables 1.webp',
              itemName: 'Grilled vegetables',
              descripetion: ''
            },
            {
              icon: 'Mix-grill 1.webp',
              itemName: 'Mix grill',
              descripetion: ''
            },
            {
              icon: 'soft.webp',
              itemName: 'Soft drink',
              descripetion: ''
            },
            {
              icon: 'tahini 1.webp',
              itemName: 'Tahini',
              descripetion: ''
            }
          ]
        }
      ]
    }
  }
]
export const Catering = () => {
  const { isDarkMode } = useSelector((state: any) => state.dark)

  const [open, setOpen] = useState(false)
  const [menu, setMenu] = useState([])
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSliderAnimating, setIsSliderAnimating] = useState(false);



const handleIndexChange = (index: number) => {
  if (sliderRef.current) {
    setCurrentSlide(index);
    setIsSliderAnimating(false);
  }
};

const handleBefore = () => {
  if (currentSlide === 0) {
    setIsSliderAnimating(false);
  } else {
    setIsSliderAnimating(true);
  }
};

  const sliderRef = useRef<any>()
  const [showCount, setShowCount] = useState(0)
  
  const handleMenu = (menu) => {
    setMenu(menu)
    setOpen(true)
  }
  const changeBackground = () => {
    if (window.innerWidth >= 1024) {
      setShowCount(3)
    } else if (window.innerWidth >= 768) {
      setShowCount(2)
    } else {
      setShowCount(1)
    }
  }
  useEffect(() => {
    setCurrentSlide(0)
    setIsSliderAnimating(false)
    changeBackground()
    window.addEventListener('resize', changeBackground)
    return () => {
      window.removeEventListener('resize', changeBackground)
    }
  }, [showCount])



  return (

    <section id="catering" className={`${isDarkMode?"bg-[#1c1c1c]":"bg-white"}`}>
      <div className='mainContainer'>
        <div className="w-full px-[10px]">
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className={`main-title-back inter h-fit text-center text-[36px]  md:text-[120px] font-[900] ${isDarkMode?"text-[#252525]":"text-[#f3f3f3] "}  mt-10`}
          >
            SERVICES
          </motion.p>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2, delay: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className={`playfair main-title-front text-[30px] mt-[-40px] md:mt-[-140px] text-center md:text-[60px] font-[900] ${isDarkMode?"text-white":"text-[#121212] "}  `}
          >
            Catering Services
          </motion.p>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2, delay: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className={`services-desc inter xl:mx-[24%]  mt-8 mb-10 text-center text-[18px] ${isDarkMode?"text-[#bebebe]":"text-[#6e6e6e]"}  lg:mt-8 lg:mb-0 `}
          >
            We exclusively provide catering and packed meal services for clients operating without own
            kitchen facilities in their work sites. As a company we can cater for 1 to 2000 + people
            on site providing a range of services
          </motion.p>
        </div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.2 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          className={`catering-swiper`}
        >
          <button
            type="button"
            className={`arrowButton catering-prev ${currentSlide === 0? isDarkMode?"disabledArrowDark":"disabledArrow":""}`}
            onClick={() => {
              if (!isSliderAnimating && currentSlide > 0) {
                sliderRef?.current?.slickPrev();
              }
            }}
            disabled={currentSlide === 0 || isSliderAnimating}
          >
            <img src="/imgs/shared/prevWhite.webp" className="prevArrow" alt="" />
          </button>
          <Slider
            centerPadding={50}
            slidesToShow={showCount}
            arrows={false}
            ref={sliderRef}
            className="slider-carousel"
            beforeChange={handleBefore}
            afterChange={handleIndexChange}>
            {content.map((item, index) => (
              <CateringCard item={item} index={index} key={index} handleMenu={handleMenu} />
            ))}
          </Slider>
          <button
            type="button"
            className={`arrowButton catering-next ${currentSlide === content.length - showCount? isDarkMode?"disabledArrowDark":"disabledArrow":""}`}
            onClick={() => {
              if (!isSliderAnimating && currentSlide < content.length - showCount) {
                sliderRef?.current?.slickNext();
              }
            }}
            disabled={currentSlide === content.length - showCount || isSliderAnimating}
          >
            <img src="/imgs/shared/nextWhite.webp" className="nextArrow" alt="" />
          </button>
        </motion.div>
      </div>
      <MenuModel open={open} setOpen={setOpen} menu={menu} />
    </section>
  )
}
