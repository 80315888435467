import { motion } from 'framer-motion'
import React from 'react'
import './Team.css'
import { useSelector } from 'react-redux'

const Team = ({ data }) => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  return (
    <section className={`${isDarkMode ? "bg-[#1c1c1c]" : "bg-white"}`} id="team">
      <div className='mainContainer'>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          className="w-full px-5 pt-[40px]"
        >
          <p className={`inter main-title-back h-fit text-center  text-[60px] font-[900] ${isDarkMode ? " text-[#252525]" : "text-[#f3f3f3]"}  lg:text-[120px]`}>
            {data.title}
          </p>
          <p className={`playfair main-title-front mt-[-60px] text-center text-[50px] font-[900] ${isDarkMode?"text-white":"text-[#121212]"}  lg:mt-[-140px] lg:text-[60px]`}>
            {data.feature}
          </p>
          <p className={`inter services-desc mx-auto mt-8 mb-10 text-center text-[18px] ${isDarkMode?"text-[#bebebe]":"text-[#6e6e6e]"}  lg:mt-8 lg:mb-0 `}>
            {data.description}
          </p>
        </motion.div>
        <motion.div className="flex w-full justify-center">
          <div className="team-container ">
            {data.team.map((item, index) => (
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.2 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 }
                }}
                key={index}
                className={`team-card relative mt-[42px] ${isDarkMode?"bg-[#111]":"bg-[#f3f3f3]"}`}
              >
                <img src={`/imgs/Teams/${item.img}`} alt={item.img} className="h-[200px] rounded-[50%] w-[200px] mt-[-42px] " />
                <div className="team-card-info">
                  <div className={`team-card-name playfair text-[28px] text-center font-black ${isDarkMode?"text-white":"text-black"}`}>{item.name}</div>
                  <div className={`team-card-position inter text-[17px] text-center font-light ${isDarkMode?"text-[#bebebe]":"text-[#6E6E6E]"}`}>{item.position}</div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  )
}

export default Team
