import React, { useRef, useState, useEffect, useMemo } from 'react'
import { HashLink } from 'react-router-hash-link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper'
import SwiperCore from "swiper";
import './Homeslider.css'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'



function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

const Homeslider = () => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  const isMobile = window.innerWidth <= 768; // You can adjust this threshold as needed
  const ref1 = useRef(null);
  const isInViewport1 = useIsInViewport(ref1);


  useEffect(()=>{
    if(isInViewport1 === false){
      setMuted(true)
    }
  },[isInViewport1])
  const [swiper, setSwiper] = useState<SwiperCore>()
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [muted, setMuted] = useState(true)
  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -80
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }
  const onAnimationComplete = () => {
    setTimeout(() => {
      swiper?.slideNext()
    }, 3000)
  }

  return (
    <motion.section
      ref={ref1}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.2 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 }
      }}
      style={{ backgroundColor: isDarkMode? "#111": ""}}
      className="homeslider-container"
    >
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        loop={false}
        // autoplay={{
        //   delay: 13000,
        //   disableOnInteraction: true
        // }}
        onInit={(ref) => setSwiper(ref)}
        pagination={{ clickable: true }}
        // navigation={true}
        speed={500}
        modules={[Autoplay, Navigation, Pagination, EffectFade]}
      // effect="fade"
      // fadeEffect={{ crossFade: true }}
      >

        <SwiperSlide>
          <div className="lg:max-w-auto relative lg:max-h-[100vh]" style={{backgroundColor: isDarkMode? "#111": ""}}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="playfair flex justify-center items-center absolute top-[50%]  z-20 mt-11 min-h-[150px] w-full lg:mt-0 lg:min-h-[250px] "
            >
              <div className='flex flex-col mainContainer'>

                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: 0.5 }}
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 }
                  }}
                  className='text-[30px] welcome-home text-left font-[900] text-white   xl:text-[58px] 2xl:text-[62px]'
                >
                  Welcome To
                </motion.p>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: 0.5 }}
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 }
                  }}
                  className='text-[30px] welcome-home text-left font-[900] text-white   xl:text-[58px] 2xl:text-[62px]'
                >
                  Abela Catering Services
                </motion.p>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 1.5, delay: 1 }}
                  onAnimationComplete={onAnimationComplete}
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 }
                  }}
                  className='text-[30px] welcome-desc font-light mt-[20px] inter text-left text-white   2xl:text-[36px]'>
                  The Pioneer In Egypt’s Catering Industry
                </motion.p>
              </div>

            </motion.div>

            <img src={`${isMobile?"/imgs/Home/HeroImage-Mobile.webp":"/imgs/Home/HeroImage.webp"}`} alt='' className='h-[100vh] w-full object-cover ' loading='lazy'/>
          </div>

          <div className="swiper-pagination"></div>
          <HashLink
            smooth
            to="#Whoweare"
            scroll={(el: any) => scrollWithOffset(el)}
            className="scrollDownContainer"
            onClick={() => { }}
          >
            <div className="mousey ">
              <div className="scroller"></div>
            </div>
          </HashLink>
        </SwiperSlide>
        <SwiperSlide>
          <div className="lg:max-w-auto relative flex justify-center lg:max-h-[100vh] ">
            <video ref={videoRef} autoPlay muted={muted} loop className="h-[100vh] w-full object-cover lg:h-[114vh] ">
              <source
                src="/imgs/Home/Sequence 04.webm"
                type="video/webm"
              />
            </video>
            <button id="volumeButton" className="volume-button" onClick={() => setMuted(!muted)}>
              {muted ?
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                </svg>
              }

            </button>
          </div>
          <div className="swiper-pagination"></div>
          <HashLink
            smooth
            to="#Whoweare"
            scroll={(el: any) => scrollWithOffset(el)}
            className="scrollDownContainer"
            onClick={() => { }}
          >
            <div className="mousey ">
              <div className="scroller"></div>
            </div>
          </HashLink>
        </SwiperSlide>
      </Swiper>
    </motion.section>
  )
}

export default Homeslider
