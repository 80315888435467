import { motion } from 'framer-motion'
import React, { useState } from 'react'
import './Practices.css'
import VideoModel from '../shared/VideoModel'
import PracticesImageCard from './PracticesImageCard'
import { useSelector } from 'react-redux'

export const Practices = () => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  const [open, setOpen] = useState(false)
  const data = {
    headText: 'PRACTICES',
    title: 'HSE Practices',
    description:
      `Maintaining strict hygiene practices is a non-negotiable aspect of our operations at Abela Catering Services. We follow rigorous hygiene protocols in all our production units to ensure that our food products are safe, fresh and free from contamination.
      Our team of highly trained personnel adheres to strict food handling and sanitation guidelines, including:
      `,
    bullets: [
      'Regular handwashing',
      'Wearing of protective gear',
      'Cleaning and sanitizing of equipment, surfaces and proper waste disposal',
    ],
    photos: ['/imgs/HSE/7.webp', '/imgs/HSE/5.webp', '/imgs/HSE/Practices03.jpg', '/imgs/HSE/Practices04.jpg'],
    video: {
      img: 'video.webp',
      vLink: '/imgs/HSE/HSE.mp4'
    }
  }
  return (

    <section className={`practice-container ${isDarkMode?"bg-[#1c1c1c]":"bg-white"}`}>
      <div className='mainContainer'>
        <div className='hse-practice flex flex-col lg:flex-row  lg:justify-between gap-[32px]'>


          <div className={`practice-info ${isDarkMode?"bg-[#111]":"bg-[#f3f3f3]"}`}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="head"
            >
              <div className="line"></div>
              <div className="inter text-[14px] font-semibold text-[#70C2B4] ">{data.headText}</div>
            </motion.div>
            <motion.h2
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2, delay: 0.05 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className={`left-title playfair text-[58px] font-black ${isDarkMode? "text-white":"text-black"}`}
            >
              {data.title}
            </motion.h2>
            <div className='bottom'>

              <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.2, delay: 0.1 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 }
                }}
                className={`inter  lg:max-w-full  xl:pt-[35px] pb-[35px] text-[17px] font-normal ${isDarkMode?"text-[#bebebe]":"text-[#6E6E6E]"} `}
              >
                {data.description}
              </motion.p>
              <div className="bullet-container">
                {data.bullets.map((item, index) => (
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.2, delay: index * 0.05 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }}
                    className="practices-bullet"
                    key={index}
                  >
                    <img src="/imgs/shared/quality@2x.webp" alt="" />
                    <p className={`inter text-[17px] font-normal ${isDarkMode?"text-[#bebebe]":"text-[#6E6E6E]"} `}>{item}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
          <div className="practice-photos ">
            {data.photos.map((item, index) => (
              <PracticesImageCard item={item} index={index} />
            ))}
          </div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="practice-video"
          >
            <img src={`/imgs/HSE/${data.video.img}`} alt="practice" className="practice-video-bg" loading='lazy'/>
            <button className="video-play-btn" onClick={() => setOpen(!open)}>
              <div className="btn-box">
                <img src="/imgs/shared/play-button-arrowhead.webp" alt="" />
              </div>
            </button>
          </motion.div>
        </div>
      </div>
      <VideoModel open={open} setOpen={setOpen} url={data.video.vLink} />
    </section>
  )
}
