import React from 'react'
import { motion } from 'framer-motion'
import './Facility.css'
import { useSelector } from 'react-redux'

export const Facility = () => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  const content = [
    {
      title: 'Housekeeping',
      img: '/imgs/Services/vrhotel@2x.webp',
      class: "img1"
    },
    {
      title: 'Full event planning',
      img: '/imgs/Services/FullEventPlaning.jpg',
      class: "img2"
    },
    {
      title: 'Facility Management',
      img: '/imgs/Services/FacilityManagement.jpg',
      class: "img3"
    },
    {
      title: 'Laundry',
      img: '/imgs/Services/Laundry.jpg',
      class: "img4"
    },
    {
      title: 'Gardening & landscape',
      img: '/imgs/Services/Gardening.jpg',
      class: "img5"
    },
  ]

  return (

    <section className={`${isDarkMode?"bg-[#111]":"bg-[#F3F3F3]"}`} id='facility'>
      <div className='mainContainer'>
        <div className="w-full  ">
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className={`inter main-title-back  h-fit text-center text-[36px]  md:text-[72px] font-[900] ${isDarkMode?"text-[#1c1c1c]":"text-[#e9e6e6]"} lg:text-[120px] mt-10`}
          >
            SERVICES
          </motion.p>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2, delay: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className={`playfair main-title-front mt-[-40px] text-center text-[30px] font-[900] ${isDarkMode?"text-white":"text-[#121212]"}  lg:mt-[-140px] lg:text-[60px]`}
          >
            Facility Services
          </motion.p>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2, delay: 0.4 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className={`inter services-desc  lg:mx-[24%] mt-8 mb-10 text-center text-[18px] ${isDarkMode?"text-[#bebebe]":"text-[#6e6e6e]"} text-[#6e6e6e] lg:mt-8 lg:mb-0 `}
          >
            We take pride in our warm and attentive service, ensuring that every guest feels welcomed and pampered.
          </motion.p>
        </div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.2 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          className={`facility-container`}
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2 }}
            className="gap-card facil"
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}>
            <motion.div
              key={content[0].class}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="facility-card bg-center"
              style={{ backgroundImage: `url(${content[0].img})`, width: '100%' }}
            >
              <div className="facility-shadow">
                <p className={`facility-title playfair text-[28px] font-black text-[white]`}>
                  {content[0].title}
                </p>

              </div>
            </motion.div>
            <motion.div
              key={content[2].class}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="facility-card bg-center"
              style={{ backgroundImage: `url(${content[2].img})`, width: '100%' }}
            >
              <div className="facility-shadow">
                <p className={`facility-title playfair text-[28px] font-black text-[white]`}>
                  {content[2].title}
                </p>

              </div>
            </motion.div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2 }}

            className="w-[31%] facility-card middleCard"
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}>
            <motion.div
              key={content[1].class}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className='lg:h-[560px] lg:w-full h-full bg-cover bg-center'
              style={{ backgroundImage: `url(${content[1].img})`}}
            >
              <div className="facility-shadow">
                <p className={`facility-title playfair text-[28px] font-black text-[white]`}>
                  {content[1].title}
                </p>

              </div>
            </motion.div>

          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2 }}
            className='gap-card'
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}>
            <motion.div
              key={content[3].class}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="facility-card bg-center"
              style={{ backgroundImage: `url(${content[3].img})`, width: '100%' }}
            >
              <div className="facility-shadow">
                <p className={`facility-title playfair text-[28px] font-black text-[white]`}>
                  {content[3].title}
                </p>

              </div>
            </motion.div>
            <motion.div
              key={content[4].class}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="facility-card bg-center"
              style={{ backgroundImage: `url(${content[4].img})`, width: '100%' }}
            >
              <div className="facility-shadow">
                <p className={`facility-title playfair text-[28px] font-black text-[white]`}>
                  {content[4].title}
                </p>

              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  )
}
