import React from 'react'
import { Helmet } from 'react-helmet'
import GetInTouch from '../components/contactus/GetInTouch'
import { Hero } from '../components/shared/hero/Hero'

const ContactUs = () => {
  const hero = {
    head: 'WELCOME TO ABELA',
    title: 'Contact Us',
    shortTitle: 'CONTACT US',
    slogan: `High staff qualifications with expertise in the field of culinary arts and hospitality allow us to provide under all circumstances professional services`,
    href: '/contactus',
    linkTo: '/contactus#getintouch',
    backGround: '/imgs/Contact/HeroImage.webp',
    mobBackGround: '/imgs/Contact/HeroImage-Mobile.webp',
  }
  const getintouch = {
    headText: 'GET IN TOUCH',
    title: 'Keep Connected And Get In Touch With Us',
    address: '160, 26th of July Corridor, Al Agouzah, Cairo, Egypt',
    phone: { tel: '(+202) 3305 0040', fax: '(+202) 3305 0020' },
    email: 'info@abelaegypt.com',
    socialLinks: {
      facebook: 'https://www.facebook.com/AbelaEgypt',
      linkedin: 'https://www.linkedin.com/company/abela-egypt/'
    },
    contactAPI: 'https://api.gemini.media/api/ExternalContactUs/Abela'
  }
  return (
    <section>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABELA | CONTACT US</title>

        <link rel="icon" type="image/webp" href="/imgs/Logos/Icon.png" sizes="16x16" />
      </Helmet>
      <Hero data={hero} />
      <GetInTouch data={getintouch} />
    </section>
  )
}

export default ContactUs
