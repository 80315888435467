import React from 'react'
import './Testimotial.css'
import { useSelector } from 'react-redux'

import { motion } from 'framer-motion'

export const Testimotial = () => {
  const { isDarkMode } = useSelector((state: any) => state.dark)

  const data = {
    headText: 'TESTIMONIAL',
    title: 'Our Vision and Mission',
    description: `At Abela Catering Services, our vision is to create extraordinary culinary experiences that tantalize the senses, elevate events to new heights, and leave a lasting impression on every guest.`,
    bullets: [
      {
        icon: '/imgs/Home/01.ArtistryFood.png',
        text: 'Unleashing the Artistry of Food',
        alt: 'quality'
      },
      {
        icon: '/imgs/Home/02.CustomizedCulinaryExperiences.png',
        text: 'Customized Culinary Experiences',
        alt: 'positive'
      },
      {
        icon: '/imgs/Home/03.Impeccable Service.png',
        text: 'Impeccable Service',
        alt: 'hotel room services'
      },
      {
        icon: '/imgs/Home/04.QualityAndSustainability.png',
        text: 'Quality and Sustainability',
        alt: 'effort'
      },
      {
        icon: '/imgs/Home/05.UnforgettableMemories.png',
        text: 'Unforgettable Memories',
        alt: 'business'
      }
    ],
    secDescription: `Abela Egypt’s focus on our core business of providing world class catering and facility
    services, allows our clients to concentrate on their core business.`,
    provided: 'Food is an art, and we are the master artists',
    img: '/imgs/Home/Mission.jpg',
    lowImg: '/imgs/Home/Mission.jpg'
  }
  return (

    <div className={`testimotial ${isDarkMode ? "bg-[#1c1c1c]" : "bg-white"}`} id="testimotial">
      <div className='mainContainer'>
        <div className="testimotialContainer">
          <div className="left-testimotial">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="head"
            >
              <div className="line"></div>
              <p className="inter text-[14px] font-semibold text-[#70C2B4]">{data.headText}</p>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className={`testimotial-text  playfair mb-[34px] w-[424px] text-[58px] font-black ${isDarkMode ? "text-white" : "text-black"}`}
            >
              {data.title}
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2, delay: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className={`inter mb-[40px] max-w-[604px] text-[18px] font-normal ${isDarkMode ? "text-[#bebebe]" : "text-[#6E6E6E]"} `}
            >
              {data.description}
            </motion.div>
            <div className="bulletsContainer">
              {data.bullets.map((bullet, index) => (
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.2, delay: 0.2 }}
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 }
                  }}
                  className="bullet"
                  key={index}
                >
                  <div className="bulletIcon">
                    <img className="m-[15%] h-[40px] w-[40px]" src={bullet.icon} alt={bullet.alt} />
                  </div>
                  <div className={`bullet-text inter max-w-[495px] text-[18px] font-normal ${isDarkMode ? "text-[#bebebe]" : "text-[#6E6E6E]"}`}>
                    {bullet.text}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
          <div className="right-testimotial mt-[2rem] w-[100%]">
            {/* <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className=" inter max-w-[600px] text-[18px] font-normal text-[#6E6E6E]"
            >
            {data.secDescription}
          </motion.div> */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2, delay: 0.4 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className={`playfair my-[40px] border-l-4 border-[#70C2B4] pl-[20px] text-[22px] font-bold italic ${isDarkMode?"text-[#bebebe]":"text-[#6E6E6E]"}`}
            >
              {data.provided}
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2, delay: 0.6 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}

            >
              <img src={data.img} alt={data.title} className='testimontail-img' loading='lazy'/>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}
