import React from 'react'
import { Helmet } from 'react-helmet'
import { CateringSectors } from '../components/sectors/cateringSectors'
import { Hero } from '../components/shared/hero/Hero'

const Sectors = () => {
  const content = {
    head: 'WELCOME TO ABELA',
    title: 'Sectors',
    shortTitle: 'Sectors',
    slogan: `Our extensive experience developed over many years of executive recruitment enabled us to establish a grand network among clients and candidates in all facets of the catering business`,
    href: '/sectors',
    linkTo: '/sectors#whowearegrid',
    backGround: '/imgs/Sectors/HeroImage.webp',
    mobBackGround: '/imgs/Sectors/HeroImage-Mobile.jpg'
  }
  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABELA | SECTORS</title>
        
        <link rel="icon" type="image/webp" href="/imgs/Logos/Icon.png" sizes="16x16" />
      </Helmet>
      <Hero data={content} />
      <CateringSectors />
    </div>
  )
}

export default Sectors
